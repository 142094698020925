export type RSRoute = {
    path: string;
    name: string;
    subroutes?: RSRoute
}
export type RSRoutes = {
    root: RSRoute,
    clinic: RSRoute
}
export const routes: RSRoutes = {
  root: {
    name: 'root',
    path: '/',
  },
  clinic: {
    name: 'clinic',
    path: '/clinic/:id',
  },
};
