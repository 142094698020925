import React from 'react';
import {useMemo} from 'react';

export type RSNavBarBrandProps = {
    text?: string,
    imageSrc?: string
}
export const RSNavBarBrand = ({text, imageSrc}: RSNavBarBrandProps): JSX.Element => {
  const imgClasses = useMemo(() => {
    return text !== undefined ? 'd-inline-block align-text-top' : '';
  }, [text]);

  // TODO: check hrefat some point
  return <a className="navbar-brand" href="/">
    {imageSrc && <img src={imageSrc} alt="" width="30" height="24" className={imgClasses}></img>}
    {text}
  </a>;
};
