import React from 'react';
import { useCallback } from 'react';
import { RSFormInputType } from '../form';

export type RSInputGroupInputProps = {
    value: string;
    type?: RSFormInputType;
    onChange: (value: string) => void;
    ariaLabel?: string;
    ariaDescribedBy?: string;
    placeholder?: string;
    invalidText?: string;
    required?: boolean;
};

// TODO: test
export const RSInputGroupInput = ({
    ariaLabel,
    type = 'text',
    ariaDescribedBy,
    placeholder,
    invalidText,
    onChange,
    value,
    required = false
}: RSInputGroupInputProps): JSX.Element => {
    const change = useCallback(
        (change: React.ChangeEvent<HTMLInputElement>) => {
            onChange(change.target.value);
        },
        [onChange],
    );
    return (
        <>
            <input
                type={type}
                className="form-control"
                placeholder={placeholder}
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedBy}
                onChange={change}
                value={value}
                required={required}
            />
            <div className="invalid-feedback">{invalidText}</div>
        </>
    );
};
