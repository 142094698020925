import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useCallback, useMemo, useReducer } from 'react';
import { RSPageContainer, RSList, http, DBRequest } from '../../common';
import { RSPagination } from '../../common/components/pagination';
import { useNavigate } from 'react-router-dom';

type Clinic = {
    uuid: string;
    name: string;
    created: string;
};

type ClinicPage = {
    number: number;
    etag: string;
    clinics: Array<Clinic>;
};

type ClinicListState = {
    pages: {
        [x: number]: ClinicPage;
    };
    currentPage: number;
    totalPages: number;
};

type AddPage = {
    type: 'AddPage';
    payload: {
        number: number;
        etag: string;
        clinics: Array<Clinic>;
        totalPageNumber: number;
    };
};

type SetCurrentPage = {
    type: 'SetCurrentPage';
    payload: {
        currentPage: number;
    };
};

type Actions = AddPage | SetCurrentPage;

function ClinicListReducer(
    state: ClinicListState,
    action: Actions,
): ClinicListState {
    switch (action.type) {
        case 'AddPage':
            state.pages[action.payload.number] = {
                number: action.payload.number,
                clinics: action.payload.clinics,
                etag: action.payload.etag,
            };
            return { ...state, totalPages: action.payload.totalPageNumber };
        case 'SetCurrentPage':
            return { ...state, currentPage: action.payload.currentPage };
    }
}

export const ClinicList = (): JSX.Element => {
    const navigate = useNavigate();
    const [clinicList, clinicListDispatcher] = useReducer(ClinicListReducer, {
        pages: [],
        currentPage: 0,
        totalPages: 0,
    });
    // TODO: check if dependencies correct
    const changePage = useCallback((page: number) => {
        clinicListDispatcher({
            type: 'SetCurrentPage',
            payload: { currentPage: page },
        });
        const etag = clinicList.pages[page]?.etag;
        http.get<DBRequest<Clinic>>(
            `/clinic/page?pageNumber=${page}&pageSize=10`,
            {
                headers: {
                    'if-none-match': etag == undefined ? '' : etag,
                },
            },
        )
            .then((response) => {
                //Ignore when nothing changed
                if (response.request.status == 304) return;
                clinicListDispatcher({
                    type: 'AddPage',
                    payload: {
                        clinics: response.data.data?.elements || [],
                        etag: response.headers['etag'] || '',
                        number: response.data.data?.pageNumber || 0,
                        totalPageNumber:
                            response.data.data?.totalNumberOfPages || 0,
                    },
                });
            })
            .catch((error) => console.error(error));
    }, []);
    const currentPage = useMemo(() => {
        const pages = clinicList.pages[clinicList.currentPage]?.clinics;
        if (pages == undefined) return [];
        return pages;
    }, [clinicList]);

    useEffect(() => {
        http.get<DBRequest<Clinic>>(
            `/clinic/page?pageNumber=${clinicList.currentPage}&pageSize=10`,
        )
            .then((response) => {
                clinicListDispatcher({
                    type: 'AddPage',
                    payload: {
                        clinics: response.data.data?.elements || [],
                        etag: response.headers['ETag'],
                        number: response.data.data?.pageNumber || 0,
                        totalPageNumber:
                            response.data.data?.totalNumberOfPages || 0,
                    },
                });
            })
            .catch((error) => console.log(error));
    }, []);

    const onOpenClinic = useCallback((uuid: string) => {
        navigate(`/admin/clinic/${uuid}`);
    }, []);

    return (
        <RSPageContainer className='h-75'>
            <ClinicListHead />
            <RSList
                elements={currentPage}
                render={(el) => (
                    <div>
                        <ClinicListElement data={el} onClick={onOpenClinic} />
                    </div>
                )}
            />
            <div className="container w-100">
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <RSPagination
                            ariaLabel={i18next.t(
                                'common.pagination.aria.label',
                            )}
                            nextText={i18next.t('common.pagination.next')}
                            previousText={i18next.t(
                                'common.pagination.previous',
                            )}
                            total={clinicList.totalPages}
                            onChange={changePage}
                            value={clinicList.currentPage}
                        />
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </RSPageContainer>
    );
};

const ClinicListHead = (): JSX.Element => {
    return (
        <div className="list-group-item mb-1 bg-secondary">
            <div className="d-container">
                <div className="row">
                    <div className="col">{i18next.t('common.clinic.uuid')}</div>
                    <div className="col">{i18next.t('common.clinic.name')}</div>
                    <div className="col">
                        {i18next.t('common.clinic.created')}
                    </div>
                </div>
            </div>
        </div>
    );
};

type ClinicListElementProps = {
    data: Clinic;
    onClick: (uuid: string) => void;
};
const ClinicListElement = ({
    data,
    onClick,
}: ClinicListElementProps): JSX.Element => {
    const dateTime = useMemo(() => {
        const dateObject = new Date(data.created);
        return (
            dateObject.toLocaleDateString() +
            ' ' +
            dateObject.toLocaleTimeString()
        );
    }, [data.created]);
    const clickOnComponent = useCallback(() => onClick(data.uuid), [data.uuid]);
    return (
        <a
            className="d-container link-dark"
            style={{ textDecoration: 'none' }}
            onClick={clickOnComponent}
        >
            <div className="row">
                <div className="col">
                    <span>{data.uuid}</span>
                </div>
                <div className="col">
                    <span>{data.name}</span>
                </div>
                <div className="col">
                    <span>{dateTime}</span>
                </div>
            </div>
        </a>
    );
};
