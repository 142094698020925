import i18next from 'i18next';
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    http,
    RefreshTokenModel,
    RSPageContainer,
    RSButton,
    RSCard,
    RSCardBody,
    RSInputGroupContainer,
    RSInputGroupInput,
    RSInputGroupText,
    RSCardImage,
    RSForm,
} from '../common';
import { applicationStore } from '../App';

type LoginModel = {
    username: string;
    password: string;
};

export const LoginPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const setGroup = applicationStore((state) => state.setGroup);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [notSuccessful, setNotSuccessful] = useState(false);

    const submit = (): void => {
        setNotSuccessful(false);
        http.post<LoginModel, RefreshTokenModel>('/auth/login', {
            username: userName,
            password: password,
        })
            .then((resp) => {
                if (resp.data.data != undefined) {
                    localStorage.setItem(
                        'rf-token',
                        resp.data.data?.refreshToken,
                    );
                    localStorage.setItem('access-token', resp.data.data?.token);
                    localStorage.setItem('group', resp.data.data.group);
                    setGroup(resp.data.data.group);
                    if (resp.data.data.group === 'root') {
                        navigate('/admin/clinic');
                    } else {
                        navigate('/');
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setNotSuccessful(true);
            });
    };
    return (
        <RSPageContainer>
            <div className="w-50 container d-flex align-items-center justify-content-center mt-5">
                <RSCard className="w-100">
                    <RSCardImage width={50} src="Logo_Green.svg" alt="test" />
                    <RSCardBody>
                        <RSForm className="p-3" onSubmit={submit}>
                            <RSInputGroupContainer className="flex mb-2 w-100 has-validation">
                                <RSInputGroupText>@</RSInputGroupText>
                                <RSInputGroupInput
                                    onChange={setUserName}
                                    value={userName}
                                    ariaDescribedBy=""
                                    ariaLabel=""
                                    required={true}
                                    placeholder={t('common.username.name')}
                                    invalidText={t('common.username.missing')}
                                />
                            </RSInputGroupContainer>
                            <RSInputGroupContainer className="flex mb-2 w-100 has-validation">
                                <RSInputGroupInput
                                    type="password"
                                    onChange={setPassword}
                                    value={password}
                                    ariaDescribedBy=""
                                    ariaLabel=""
                                    required={true}
                                    placeholder={t('common.password.name')}
                                    invalidText={t('common.password.missing')}
                                />
                            </RSInputGroupContainer>
                            <div
                                className="row text-center mt-2 mb-2"
                                style={{ display: notSuccessful ? '' : 'none' }}
                            >
                                <div className="text-danger">
                                    {t('common.login.not.successful')}
                                </div>
                            </div>
                            <div className="d-grid gap-2">
                                <RSButton type="submit">
                                    {t('common.login.name')}
                                </RSButton>
                            </div>
                        </RSForm>
                    </RSCardBody>
                </RSCard>
            </div>
        </RSPageContainer>
    );
};
