import React, { useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    http,
    Address,
    ContactInformation,
    RSCard,
    RSCardBody,
    RSFormInput,
    RSPageContainer,
    RSAddresses,
    RSInputGroupContainer,
    RSInputGroupButton,
    RSButtonGroup,
    RSButton,
} from '../../common';
import { RSContactInformations } from '../../common';

function createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        },
    );
}

type UserComplete = {
    uuid: string;
    userName: string;
    firstName: string;
    lastName: string;
    group: {
        uuid: string;
        name: string;
    };
    addresses: Array<Address>;
    contactInformations: Array<ContactInformation>;
    created: string;
};

type UserEditState = {
    etag?: string;
    data: UserComplete;
};

type SetUsername = {
    type: 'SetUsername';
    payload: {
        value: string;
    };
};

type SetEmail = {
    type: 'SetEmail';
    payload: {
        value: string;
    };
};

type SetFirstName = {
    type: 'SetFirstName';
    payload: {
        value: string;
    };
};

type SetLastName = {
    type: 'SetLastName';
    payload: {
        value: string;
    };
};

type SetContactInformation = {
    type: 'SetContactInformation';
    payload: {
        index: number;
        value: ContactInformation;
    };
};

type AddContactInformation = {
    type: 'AddContactInformation';
    payload: {
        newSize: number;
    };
};

type DeleteContactInformation = {
    type: 'DeleteContactInformation';
    payload: {
        index: number;
        sizeBefore: number;
    };
};

type SetAddress = {
    type: 'SetAddress';
    payload: {
        value: Address;
    };
};

type SetData = {
    type: 'SetData';
    payload: {
        etag: string;
        data: UserComplete;
    };
};

type Action =
    | SetUsername
    | SetEmail
    | SetFirstName
    | SetLastName
    | SetContactInformation
    | AddContactInformation
    | DeleteContactInformation
    | SetAddress
    | SetData;

function UserEditStateReducer(
    state: UserEditState,
    action: Action,
): UserEditState {
    switch (action.type) {
        case 'SetData':
            return {
                etag: action.payload.etag,
                data: action.payload.data,
            };
        case 'SetUsername':
            return {
                etag: state.etag,
                data: {
                    ...state.data,
                    userName: action.payload.value,
                },
            };

        case 'SetFirstName':
            return {
                etag: state.etag,
                data: {
                    ...state.data,
                    firstName: action.payload.value,
                },
            };
        case 'SetLastName':
            return {
                etag: state.etag,
                data: {
                    ...state.data,
                    lastName: action.payload.value,
                },
            };
        case 'SetContactInformation':
            state.data.contactInformations[action.payload.index] =
                action.payload.value;
            return {
                etag: state.etag,
                data: {
                    ...state.data,
                },
            };
        case 'AddContactInformation':
            for (let index = 0; index < action.payload.newSize; index++) {
                if (state.data.contactInformations[index] !== undefined)
                    continue;
                state.data.contactInformations.push({
                    uuid: createUUID(),
                    type: '',
                    value: '',
                });
            }
            return {
                ...state,
            };

        case 'DeleteContactInformation':
            if (
                action.payload.sizeBefore - 1 ===
                state.data.contactInformations.length
            )
                return state;
            state.data.contactInformations.splice(action.payload.index, 1);
            return {
                ...state,
            };
        case 'SetAddress':
            state.data.addresses[0] = action.payload.value;
            return {
                ...state,
            };
    }

    return state;
}

export const UserEdit = (): JSX.Element => {
    const { uuid } = useParams();
    const { t } = useTranslation();
    const [state, dispatcher] = useReducer(UserEditStateReducer, {
        data: {
            uuid: '',
            userName: '',
            firstName: '',
            lastName: '',
            group: { uuid: '', name: '' },
            addresses: [],
            contactInformations: [],
            created: t('common.notcreated'),
        },
    });

    useEffect(() => {
        if (uuid == null || uuid === 'new') {
            dispatcher({
                type: 'SetData',
                payload: {
                    etag: '',
                    data: {
                        addresses: [
                            {
                                city: '',
                                countryCode: '',
                                region: '',
                                street: '',
                                uuid: '',
                                zip: '',
                            },
                        ],
                        contactInformations: [],
                        created: '',
                        firstName: '',
                        group: { name: '', uuid: '' },
                        lastName: '',
                        userName: '',
                        uuid: '',
                    },
                },
            });
            return;
        }
        http.get<UserComplete>(`/user/${uuid}`)
            .then((response) => {
                dispatcher({
                    type: 'SetData',
                    payload: {
                        etag: response.headers['etag'],
                        data: response.data.data!,
                    },
                });
            })
            .then((error) => console.log(error));
    }, []);

    return (
        <RSPageContainer>
            <RSCard>
                <RSCardBody>
                    <RSFormInput
                        id="userName-form-input"
                        label="common.username.name"
                        ariaLabel="common.username.name"
                        value={state.data.userName}
                        onChange={(value) =>
                            dispatcher({
                                type: 'SetUsername',
                                payload: { value: value },
                            })
                        }
                    />
                    <RSFormInput
                        id="userName-form-input"
                        label="common.firstname"
                        ariaLabel="common.firstname"
                        value={state.data.firstName}
                        onChange={(value) =>
                            dispatcher({
                                type: 'SetFirstName',
                                payload: { value: value },
                            })
                        }
                    />
                    <RSFormInput
                        id="userName-form-input"
                        label="common.lastname"
                        ariaLabel="common.lastname"
                        value={state.data.lastName}
                        onChange={(value) =>
                            dispatcher({
                                type: 'SetLastName',
                                payload: { value: value },
                            })
                        }
                    />
                    <RSFormInput
                        id="userName-form-input"
                        label="common.created"
                        ariaLabel="common.created"
                        value={state.data.created}
                        disabled={true}
                    />

                    <RSContactInformations
                        label="common.contact.information.name"
                        data={state.data.contactInformations}
                        add={() => {
                            dispatcher({
                                type: 'AddContactInformation',
                                payload: {
                                    newSize:
                                        state.data.contactInformations.length +
                                        1,
                                },
                            });
                        }}
                        onChange={(index, value) =>
                            dispatcher({
                                type: 'SetContactInformation',
                                payload: {
                                    index: index,
                                    value: value,
                                },
                            })
                        }
                        onDelete={(index) =>
                            dispatcher({
                                type: 'DeleteContactInformation',
                                payload: {
                                    index: index,
                                    sizeBefore:
                                        state.data.contactInformations.length,
                                },
                            })
                        }
                    />

                    <RSAddresses
                        label="common.addresses"
                        data={state.data.addresses}
                        onChange={(index, value) =>
                            dispatcher({
                                type: 'SetAddress',
                                payload: {
                                    value: value,
                                },
                            })
                        }
                    />
                    <div className="d-flex flex-row-reverse mt-2">
                        <RSButtonGroup className="w-25">
                            <RSButton
                                type="button"
                                color="btn-danger"
                                onClick={() => console.log()}
                            >
                                {t('common.delete')}
                            </RSButton>
                            <RSButton
                                type="button"
                                color="btn-success"
                                onClick={() => console.log()}
                            >
                                {t('common.save')}
                            </RSButton>
                        </RSButtonGroup>
                    </div>
                </RSCardBody>
            </RSCard>
        </RSPageContainer>
    );
};
