import React, { useCallback, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RSNav, RSNavBarBrandProps, RSNavItem, RSNavLink } from '../common';

const brand: RSNavBarBrandProps = {
    imageSrc: '/logo.svg',
    text: 'Reha_select',
};

const toggler = {
    ariaLabel: 'test',
};

export const Index = (): JSX.Element => {
    const [collapsed, setCollapsed] = useState(true);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const brandRef = useRef(brand);
    const togglerRef = useRef(toggler);

    const toggleCollapse = useCallback(
        () => setCollapsed((prev) => !prev),
        [setCollapsed],
    );
    const changePage = useCallback((path: string): void => {
        navigate(path);
        setCollapsed(true);
    }, []);

    return (
        <div>
            <RSNav
                id="public-menu"
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
                brand={brandRef.current}
                toggler={togglerRef.current}
            >
                <RSNavItem>
                    <RSNavLink
                        path="/"
                        text="home"
                        click={changePage}
                        active={pathname}
                    />
                </RSNavItem>
            </RSNav>
            <Outlet />
        </div>
    );
};
