import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RSButton } from '../buttons';
import { RSCard, RSCardBody } from '../card';
import {
    RSInputGroupButton,
    RSInputGroupContainer,
    RSInputGroupInput,
    RSInputGroupText,
} from '../input-group';
import { RSInputGroupSelect } from '../input-group/RSInputGroupSelect';

export type ContactInformation = {
    uuid: string;
    type: string;
    value: string;
};

export type RSContactInformationsProps = {
    label: string;
    data: Array<ContactInformation>;
    onChange: (index: number, value: ContactInformation) => void;
    onDelete: (index: number) => void;
    add: () => void;
};

export const RSContactInformations = ({
    label,
    data,
    onChange,
    onDelete,
    add,
}: RSContactInformationsProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="form-label">{t(label)}</div>
            <RSCard>
                <RSCardBody>
                    <div>
                        {data.map((element, index) => (
                            <RSContactInformation
                                key={element.uuid}
                                data={element}
                                deleteCI={() => onDelete(index)}
                                onChange={(value) => onChange(index, value)}
                            />
                        ))}
                        <RSButton
                            type="button"
                            onClick={add}
                            className="w-100 mt-2"
                        >
                            {t('common.add')}
                        </RSButton>
                    </div>
                </RSCardBody>
            </RSCard>
        </div>
    );
};

type RSContactInformationProps = {
    data: ContactInformation;
    deleteCI: () => void;
    onChange: (value: ContactInformation) => void;
};

const RSContactInformation = ({
    data,
    deleteCI,
    onChange,
}: RSContactInformationProps): JSX.Element => {
    const { t } = useTranslation();
    const [type, setType] = useState<string>(data.type);
    const [value, setValue] = useState<string>(data.value);
    const options = useMemo(
        () => [
            { value: 'EMAIL', text: t('common.email') },
            { value: 'TEL', text: t('common.tel') },
        ],
        [],
    );

    useEffect(() => {
        if (type !== data.type || value !== data.value) {
            onChange({ uuid: data.uuid, type: type, value: value });
        }
    }, [onChange, type, value, data]);

    return (
        <RSInputGroupContainer className="mb-1">
            <RSInputGroupText>
                {t('common.contact.information.type')}
            </RSInputGroupText>
            <RSInputGroupSelect
                value={type}
                onChange={setType}
                options={options}
            />
            <RSInputGroupText>
                {t('common.contact.information.value')}
            </RSInputGroupText>
            <RSInputGroupInput value={value} onChange={setValue} />
            <RSInputGroupButton
                click={deleteCI}
                text="X"
                type="btn-outline-danger"
            />
        </RSInputGroupContainer>
    );
};
