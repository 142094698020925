import React, { useCallback, useRef, useState } from 'react';
import { MaybeArray } from '../../helper';

export type RSFormProps = {
    onSubmit: () => void;
    className: string;
    children?: MaybeArray<JSX.Element>;
};

export const RSForm = ({
    className,
    onSubmit,
    children,
}: RSFormProps): JSX.Element => {
    const [wasValidated, setWasValidated] = useState<string>('');
    const form = useRef<HTMLFormElement>(null);
    const validate = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            if (!form.current?.checkValidity()) {
                event.stopPropagation();
            } else {
                onSubmit();
            }
            event.preventDefault();
            setWasValidated('was-validated');
        },
        [form, onSubmit],
    );

    return (
        <form
            ref={form}
            className={wasValidated + ' ' + className}
            onSubmit={validate}
            noValidate
        >
            {children}
        </form>
    );
};
