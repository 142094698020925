import React from 'react';
import {useMemo} from 'react';
import {MaybeArray} from '../../helper';

export type RSNavContainerProps = {
    id: string,
    collapsed: boolean,
    children: MaybeArray<JSX.Element>
};

export const RSNavContainer = ({id, collapsed, children}: RSNavContainerProps): JSX.Element => {
  const classes = useMemo(() => {
    return (collapsed ? 'collapse ' : '') + 'navbar-collapse';
  }, [collapsed]);
  return <div className={classes} id={id}>
    <div className="navbar-nav me-auto mb-2 mb-lg-0">
      {children}
    </div>
  </div>;
};
