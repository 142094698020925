import React from 'react';
import {useMemo} from 'react';

export type RSNavLinkProps = {
    path: string,
    text: string,
    active?: string,
    click?: (path: string) => void
}

export const RSNavLink = ({path, text, active, click}: RSNavLinkProps): JSX.Element => {
  const classes = useMemo(() => {
    return 'nav-link ' + (active === path ? 'active' : '');
  }, [active, path]);
  const ariaCurrent = useMemo(() => {
    return (active === path ? 'page' : undefined);
  }, [active, path]);
  const clickOrHref = useMemo(() => {
    return click !== undefined ? {onClick: (e: any) => {
      e.preventDefault(); click(path);
    }} : {href: path};
  }, [click, path]);
  return <a {...clickOrHref} className={classes} aria-current={ariaCurrent}>{text}</a>;
};
