import React, { useEffect, useReducer } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Address,
    ContactInformation,
    http,
    RSButton,
    RSCard,
    RSCardBody,
    RSComment,
    RSImage,
    RSLogo,
    RSPageContainer,
    RSRating,
} from '../../common';
import { ClinicBody } from './ClinicBody';
import { ClinicTitle, ImageCarousel } from './ClinicTitle';

import { TestClinicData } from '../../test-data';
import { t } from 'i18next';
import { ArrowRight } from '../../common/svg';
import { RSAwards } from '../../common/components/advanced/RSAwards';
import { stringify } from 'querystring';
import { API_URL } from '../../App';

type ETag = {
    etag: string;
};

type Lang = {
    lang: string;
};

type ClinicComplete = {
    name: string;
    created: string;
    avgRating: string;
    text: { data: string } & ETag & Lang;
    address: { data: Array<Address> } & ETag;
    contactInformations: { data: Array<ContactInformation> } & ETag;
    images: { data: Array<string> } & ETag;
    departments: { data: Array<{ name: string }> } & ETag;
    highlights: { data: Array<{ logo: RSLogo; name: string }> } & ETag;
    languages: { data: Array<{ name: string }> } & ETag;
    certificates: { data: Array<string> } & ETag;
    comments: Array<{
        userUuid: string;
        assessment: number;
        verified: boolean;
        text: string;
        created: string;
    }>;
} & ETag;

type SetClinicBasic = {
    type: 'SetClinic';
    payload: {
        name: string;
    } & ETag;
};

type SetText = {
    type: 'SetText';
    payload: {
        data: string;
        lang: string;
    } & ETag;
};

type SetAddresses = {
    type: 'SetAddress';
    payload: {
        data: Array<Address>;
    } & ETag;
};

type SetContactInformations = {
    type: 'SetContactInformations';
    payload: {
        data: Array<ContactInformation>;
    } & ETag;
};

type SetImages = {
    type: 'SetImages';
    payload: {
        data: Array<string>;
    } & ETag;
};

type SetLanguages = {
    type: 'SetLanguages';
    payload: {
        data: Array<{ name: string }>;
    } & ETag;
};

type SetDepartments = {
    type: 'SetDepartments';
    payload: {
        data: Array<{ name: string }>;
    } & ETag;
};

type SetCertificates = {
    type: 'SetCertificates';
    payload: {
        data: Array<string>;
    } & ETag;
};

type SetHighlights = {
    type: 'SetHighlights';
    payload: {
        data: Array<{ logo: RSLogo; name: string }>;
    } & ETag;
};

type SetComments = {
    type: 'SetComments';
    payload: {
        data: Array<{
            userUuid: string;
            assessment: number;
            verified: boolean;
            text: string;
            created: string;
        }>;
    };
};

type Action =
    | SetClinicBasic
    | SetText
    | SetAddresses
    | SetContactInformations
    | SetImages
    | SetLanguages
    | SetDepartments
    | SetCertificates
    | SetHighlights
    | SetComments;

function ClinicStateReducer(
    state: ClinicComplete,
    action: Action,
): ClinicComplete {
    switch (action.type) {
        case 'SetClinic':
            return { ...state, ...action.payload };
        case 'SetText':
            return { ...state, text: action.payload };
        case 'SetAddress':
            return { ...state, address: action.payload };
        case 'SetContactInformations':
            return { ...state, contactInformations: action.payload };
        case 'SetImages':
            return { ...state, images: action.payload };
        case 'SetLanguages':
            return { ...state, languages: action.payload };
        case 'SetDepartments':
            return { ...state, departments: action.payload };
        case 'SetCertificates':
            return { ...state, certificates: action.payload };
        case 'SetHighlights':
            return { ...state, highlights: action.payload };
        case 'SetComments':
            return { ...state, comments: action.payload.data };
        default:
            return state;
    }
}

const initialState: ClinicComplete = {
    name: '',
    created: '',
    avgRating: '5',
    address: { data: [], etag: '' },
    contactInformations: { data: [], etag: '' },
    etag: '',
    images: { data: [], etag: '' },
    text: { data: '', etag: '', lang: '' },
    departments: { data: [], etag: '' },
    highlights: { data: [], etag: '' },
    languages: { data: [], etag: '' },
    certificates: { data: [], etag: '' },
    comments: [],
};

export const Clinic = (): JSX.Element => {
    const { uuid } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [state, dispatcher] = useReducer(ClinicStateReducer, initialState);

    useEffect(() => {
        http.get<{ name: string; created: string }>(
            `/clinic/simple/${uuid}`,
        ).then((response) => {
            dispatcher({
                type: 'SetClinic',
                payload: {
                    etag: response.headers['etag'],
                    name: response.data.data?.name ?? '',
                },
            });
        });
        http.get<Array<string>>(`/image/all/${uuid}`).then((response) => {
            dispatcher({
                type: 'SetImages',
                payload: {
                    etag: response.headers['etag'],
                    data: response.data.data ?? [],
                },
            });
        });
        http.get<{ text: string; lang: string }>(`/clinic/text/${uuid}`).then(
            (response) => {
                dispatcher({
                    type: 'SetText',
                    payload: {
                        data: response.data.data?.text ?? '{}',
                        lang: response.data.data?.lang ?? '',
                        etag: response.headers['etag'],
                    },
                });
            },
        );
        http.get<Array<{ name: string }>>(`/languages/clinic/${uuid}`).then(
            (response) => {
                dispatcher({
                    type: 'SetLanguages',
                    payload: {
                        data: response.data?.data ?? [],
                        etag: response.headers['etag'],
                    },
                });
            },
        );
        http.get<Array<{ name: string }>>(`/department/clinic/${uuid}`).then(
            (response) => {
                dispatcher({
                    type: 'SetDepartments',
                    payload: {
                        data: response.data?.data ?? [],
                        etag: response.headers['etag'],
                    },
                });
            },
        );
        http.get<Array<{ name: string }>>(`/highlights/clinic/${uuid}`).then(
            (response) => {
                dispatcher({
                    type: 'SetHighlights',
                    payload: {
                        data:
                            response.data?.data?.map((e) => ({
                                logo: 'StarFill' as RSLogo,
                                name: e.name,
                            })) ?? [],
                        etag: response.headers['etag'],
                    },
                });
            },
        );
        http.get<Array<string>>(`/image/certificates/${uuid}`).then(
            (response) => {
                dispatcher({
                    type: 'SetCertificates',
                    payload: {
                        data: response.data?.data ?? [],
                        etag: response.headers['etag'],
                    },
                });
            },
        );
        http.get<
            Array<{
                userUuid: string;
                assessment: number;
                verified: boolean;
                text: string;
                created: string;
            }>
        >(`/comment/clinic/${uuid}`).then((response) => {
            dispatcher({
                type: 'SetComments',
                payload: {
                    data: response.data.data ?? [],
                },
            });
        });
    }, []);

    // TODO: get with ajax call
    const clinicData = {
        address: TestClinicData.address,
        googleMaps: TestClinicData.googleMaps,
        description: TestClinicData.description,
        avgRating: TestClinicData.avgRating,
    };
    // -------------------------
    return (
        <>
            <ImageCarousel images={state.images.data} />
            <RSPageContainer>
                <div className="d-container">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-6">
                            <ClinicTitle
                                title={state.name}
                                loading={loading}
                                address={clinicData.address}
                                googleMaps={clinicData.googleMaps}
                                departments={state.departments.data.map(
                                    (e) => e.name,
                                )}
                            />
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-6">
                            <ClinicBody
                                loading={loading}
                                text={state.text.data}
                                highlights={state.highlights.data}
                                languages={state.languages.data.map(
                                    (e) => e.name,
                                )}
                                awards={state.certificates.data.map(
                                    (element) => `${API_URL}image/${element}`,
                                )}
                            />
                            <div className="mt-3 d-container">
                                <div className="row row-cols-3">
                                    {state.images.data.map((image) => (
                                        <RSImage
                                            height="100%"
                                            width="100%"
                                            key={image}
                                            className="mb-1"
                                            src={API_URL + "image/" + image}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 sticky-top mt-5 mr-3">
                            <RSCard>
                                <RSCardBody>
                                    <div>
                                        <h4>{t('common.clinic.avg-rating')}</h4>
                                        <RSRating
                                            rating={clinicData.avgRating}
                                            max={5}
                                        />
                                        <hr></hr>
                                        <h4>
                                            {t(
                                                'common.clinic.selected-comments',
                                            )}
                                        </h4>
                                        {state.comments.map(
                                            (comment, index) => {
                                                return (
                                                    <RSComment
                                                        key={index}
                                                        userUuid={
                                                            comment.userUuid
                                                        }
                                                        rating={
                                                            comment.assessment
                                                        }
                                                        text={comment.text}
                                                        date={comment.created}
                                                        verified={
                                                            comment.verified
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                        <hr></hr>
                                        <RSButton type="button">
                                            <span>
                                                {t(
                                                    'common.clinic.all-comments',
                                                )}
                                                <ArrowRight />
                                            </span>
                                        </RSButton>
                                    </div>
                                </RSCardBody>
                            </RSCard>
                        </div>
                    </div>
                </div>
            </RSPageContainer>
        </>
    );
};
