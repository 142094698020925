import React from 'react';
import { MaybeArray } from '../../helper';

export type PageContainerProps = {
    children: MaybeArray<JSX.Element>;
    className?: string;
};

export const RSPageContainer = ({
    children,
    className = '',
}: PageContainerProps): JSX.Element => {
    return <div className={'p-5 ' + className}>{children}</div>;
};
