import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type GrowingSpinnerProps = {
    height?: string;
    width?: string;
};

export const GrowingSpinner = ({
    height,
    width,
}: GrowingSpinnerProps): JSX.Element => {
    const { t } = useTranslation();
    const styles = useMemo(() => {
        return {
            width,
            height,
        };
    }, [height, width]);
    return (
        <div style={styles} className="spinner-grow text-primary" role="status">
            <span className="visually-hidden">{t('common.loading')}</span>
        </div>
    );
};
