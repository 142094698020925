import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import { API_URL } from '../../App';
import { RSImage } from '../../common';
import { LoadableComponent } from '../../common/helper';
import { GeoAlt } from '../../common/svg';

export type Address = {
    street: string;
    zip: string;
    region: string;
    city: string;
    country: string;
};

function formatAddress(address: Address): string {
    return `${address.city}, ${address.region}`;
}

export type ClinicTitleProps = {
    title: string;
    address: Address;
    googleMaps: string;
    departments: Array<string>;
} & LoadableComponent;

export const ClinicTitle = ({
    address,
    googleMaps,
    title,
    departments,
    loading,
}: ClinicTitleProps): JSX.Element => {
    const formatAddressMemo = useRef(formatAddress);
    return (
        <div>
            <h5>
                <GeoAlt />
                <a href={googleMaps}>
                    {formatAddressMemo.current(address)}
                </a>{' '}
            </h5>
            <h1>{title}</h1>
            <div className="d-inline-block">
                {departments.map((e, index) => (
                    <Department key={index} name={e} />
                ))}
            </div>
        </div>
    );
};

type DepartmentProps = {
    name: string;
};

const Department = ({ name }: DepartmentProps): JSX.Element => {
    //space after instead of margin
    return (
        <>
            <span className="badge bg-info">{name}</span>
            <span> </span>
        </>
    );
};

export type ImageCarouselProps = {
    images: Array<string>;
};

export const ImageCarousel = ({ images }: ImageCarouselProps): JSX.Element => {
    const [active, setActive] = useState(0); //TODO: handle when nothing is passed

    const next = () => {
        if (images.length - 1 == active) {
            setActive(0);
        } else {
            setActive(active + 1);
        }
    };

    const previous = () => {
        if (active == 0) {
            setActive(images.length - 1);
        } else {
            setActive(active - 1);
        }
    };

    return (
        <div className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {images.map((uuid) => {
                    return (
                        <div
                            key={uuid}
                            className={
                                'carousel-item' +
                                (images[active] == uuid ? 'active' : '')
                            }
                        >
                            <RSImage
                                src={API_URL + "image/" + uuid}
                                className="d-block w-100"
                                alt="..."
                                width='100vh'
                                height='100%'
                            />
                        </div>
                    );
                })}
            </div>
            <button
                className="carousel-control-prev"
                type="button"
                onClick={previous}
            >
                <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                onClick={next}
            >
                <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};
