import React from 'react';
export type RSInputGroupButtonProps = {
    text: string;
    click: () => void;
    type?: 'btn-outline-secondary' | 'btn-outline-danger';
};
export const RSInputGroupButton = ({
    click,
    text,
    type = 'btn-outline-secondary',
}: RSInputGroupButtonProps): JSX.Element => {
    return (
        <button onClick={click} className={'btn ' + type} type="button">
            {text}
        </button>
    );
};
