import React from 'react';
import { MaybeArray } from '../../helper';

export type RSCardProps = {
    className?: string;
    children: MaybeArray<JSX.Element>;
};
export const RSCard = ({ className, children }: RSCardProps): JSX.Element => {
    return <div className={'card ' + className}>{children}</div>;
};
