import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RSButton } from '../buttons';
import { RSCard, RSCardBody } from '../card';
import {
    RSInputGroupContainer,
    RSInputGroupInput,
    RSInputGroupText,
} from '../input-group';

export type Address = {
    uuid: string;
    countryCode: string;
    region: string;
    city: string;
    zip: string;
    street: string;
};

export type RSAddressesProps = {
    label: string;
    data: Array<Address>;
    onChange: (index: number, value: Address) => void;
};

export const RSAddresses = ({
    label,
    data,
    onChange,
}: RSAddressesProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="form-label">{t(label)}</div>
            <RSCard>
                <RSCardBody>
                        {data.map((element, index) => (
                            <RSAddress
                                key={element.uuid}
                                data={element}
                                onChange={(value) => onChange(index, value)}
                            />
                        ))}
                </RSCardBody>
            </RSCard>
        </div>
    );
};

export type RSAddressProps = {
    data: Address;
    onChange: (value: Address) => void;
};

export const RSAddress = ({
    data,
    onChange,
}: RSAddressProps): JSX.Element => {
    const { t } = useTranslation();
    const [countryCode, setCountryCode] = useState(data.countryCode);
    const [region, setRegion] = useState(data.region);
    const [city, setCity] = useState(data.city);
    const [zip, setZip] = useState(data.zip);
    const [street, setStreet] = useState(data.street);

    useEffect(() => {
        if (
            countryCode !== data.countryCode ||
            region !== data.region ||
            city !== data.city ||
            zip !== data.zip ||
            street !== data.street
        ) {
            onChange({
                uuid: data.uuid,
                countryCode,
                region,
                city,
                zip,
                street,
            });
        }
    }, [onChange, countryCode, region, city, zip, street, data]);

    return (
        <div className="">
            <RSInputGroupContainer className="mb-1">
                <RSInputGroupText>
                    {t('common.address.countryCode')}
                </RSInputGroupText>
                <RSInputGroupInput
                    value={countryCode}
                    onChange={setCountryCode}
                />
                <RSInputGroupText>
                    {t('common.address.region')}
                </RSInputGroupText>
                <RSInputGroupInput value={region} onChange={setRegion} />
            </RSInputGroupContainer>
            <RSInputGroupContainer className="mb-1">
                <RSInputGroupText>{t('common.address.city')}</RSInputGroupText>
                <RSInputGroupInput value={city} onChange={setCity} />
                <RSInputGroupText>{t('common.address.zip')}</RSInputGroupText>
                <RSInputGroupInput value={zip} onChange={setZip} />
            </RSInputGroupContainer>
            <RSInputGroupContainer>
                <RSInputGroupText>
                    {t('common.address.street')}
                </RSInputGroupText>
                <RSInputGroupInput value={street} onChange={setStreet} />
            </RSInputGroupContainer>
        </div>
    );
};
