import React from 'react';
import { useCallback } from 'react';
import { RSFormInputType } from '../form';

export type RSInputGroupSelectProps = {
    value: string;
    onChange: (value: string) => void;
    ariaLabel?: string;
    ariaDescribedBy?: string;
    placeholder?: string;
    invalidText?: string;
    required?: boolean;
    options: Array<{value: string; text: string;}>;
};

// TODO: test
export const RSInputGroupSelect = ({
    ariaLabel,
    ariaDescribedBy,
    placeholder,
    invalidText,
    onChange,
    value,
    options,
    required = false
}: RSInputGroupSelectProps): JSX.Element => {
    const change = useCallback(
        (change: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(change.target.value);
        },
        [onChange],
    );
    return (
        <>
            <select
                className="form-control"
                placeholder={placeholder}
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedBy}
                onChange={change}
                value={value}
                required={required}
            >
                {options.map((option) => {
                    return <option key={option.value} value={option.value}>{option.text}</option>
                })}
            </select>
            <div className="invalid-feedback">{invalidText}</div>
        </>
    );
};