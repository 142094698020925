import React, { useMemo } from 'react';
import { MaybeArray } from '../../helper';
export type RSButtonProps = {
    children: string | MaybeArray<JSX.Element>;
    className?: string;
    type: 'submit' | 'button' | 'reset';
    color?:
        | 'btn-primary'
        | 'btn-secondary'
        | 'btn-warning'
        | 'btn-danger'
        | 'btn-success'
        | 'btn-info'
        | 'btn-light'
        | 'bnt-dark'
        | 'btn-link';
    onClick?: () => void;
};

export const RSButton = ({
    className,
    type = 'button',
    color ='btn-primary',
    onClick,
    children,
}: RSButtonProps): JSX.Element => {
    const classes = useMemo(() => {
        return `btn ${color} ` + (className == undefined ? '' : className);
    }, [className]);
    return (
        <button className={classes} type={type} onClick={onClick}>
            {children}
        </button>
    );
};

export type RSButtonGroupProps = {
    className?: string;
    children: MaybeArray<JSX.Element>;
    ariaLabel?: string;
};
export const RSButtonGroup = ({
    className,
    children,
    ariaLabel,
}: RSButtonGroupProps): JSX.Element => {
    return (
        <div
            className={'btn-group ' + (className ?? '')}
            role="group"
            aria-label={ariaLabel}
        >
            {children}
        </div>
    );
};
