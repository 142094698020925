import React, { useEffect, useReducer, useState } from 'react';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
    http,
    RSCard,
    RSCardBody,
    RSContactInformations,
    RSInputGroupInput,
    RSPageContainer,
    RSAddresses,
    RSFormInput,
    ClinicComplete,
} from '../../common';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';



type ClinicEditState = {
    etag?: string;
    data: ClinicComplete;
};

type SetName = {
    type: 'SetName';
    payload: {
        name: string;
    };
};

type SetData = {
    type: 'SetData';
    payload: {
        etag: string;
        data: ClinicComplete;
    };
};

type Action = SetName | SetData;
function ClinicEditStateReducer(
    state: ClinicEditState,
    action: Action,
): ClinicEditState {
    switch (action.type) {
        case 'SetName':
            return {
                etag: state.etag,
                data: { ...state.data, name: action.payload.name },
            };
        case 'SetData':
            return {
                ...action.payload,
            };
    }
}

export const ClinicEdit = (): JSX.Element => {
    const { uuid } = useParams();
    const [state, dispatcher] = useReducer(ClinicEditStateReducer, {
        data: {
            uuid: '',
            name: '',
            addresses: [],
            contactInformations: [],
            created: '',
            text: [],
            clinicHighlights: [],
            clinicDepartments: []
        },
    });

    useEffect(() => {
        if (uuid == null) return;
        http.get<ClinicComplete>(`/clinic/${uuid}`)
            .then((response) => {
                dispatcher({
                    type: 'SetData',
                    payload: {
                        etag: response.headers['etag'],
                        data: response.data.data!,
                    },
                });
            })
            .then((error) => console.log(error));
    }, []);

    return (
        <RSPageContainer>
            <RSCard>
                <div className="h-100" style={{ height: '90vh' }}>
                    <RSCardBody>
                        <RSInputGroupInput
                            onChange={(value) =>
                                dispatcher({
                                    type: 'SetName',
                                    payload: { name: value },
                                })
                            }
                            value={state.data.name}
                        />
                    </RSCardBody>
                    {state.data.text.length > 0 && <RSEditor text={state.data.text[0].text} />}
                    <RSContactInformations
                        label="common.contact-information"
                        data={state.data.contactInformations}
                        onChange={(index, value) => console.log(value)}
                        onDelete={() => {
                            console.log();
                        }}
                        add={() => console.log()}
                    />
                    <RSAddresses
                        label="common.address"
                        data={state.data.addresses}
                        onChange={(index, value) => console.log(value)}
                    />
                </div>
            </RSCard>
        </RSPageContainer>
    );
};

type MenuBarProps = {
    editor: Editor;
    triggerExport: () => void;
};

const MenuBar = ({ editor, triggerExport }: MenuBarProps): JSX.Element => {
    return (
        <>
            <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('bold') ? 'active' : '')
                }
            >
                bold
            </button>
            <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('italic') ? 'is-active' : '')
                }
            >
                italic
            </button>
            <button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('strike') ? 'is-active' : '')
                }
            >
                strike
            </button>
            <button
                onClick={() => editor.chain().focus().toggleCode().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('code') ? 'is-active' : '')
                }
            >
                code
            </button>
            <button
                onClick={() => editor.chain().focus().unsetAllMarks().run()}
                className="btn btn-primary m-1"
            >
                clear marks
            </button>
            <button
                onClick={() => editor.chain().focus().clearNodes().run()}
                className="btn btn-primary m-1"
            >
                clear nodes
            </button>
            <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('paragraph') ? 'is-active' : '')
                }
            >
                paragraph
            </button>
            <button
                onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                }
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('heading', { level: 1 })
                        ? 'is-active'
                        : '')
                }
            >
                h1
            </button>
            <button
                onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                }
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('heading', { level: 2 })
                        ? 'is-active'
                        : '')
                }
            >
                h2
            </button>
            <button
                onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                }
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('heading', { level: 3 })
                        ? 'is-active'
                        : '')
                }
            >
                h3
            </button>
            <button
                onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 4 }).run()
                }
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('heading', { level: 4 })
                        ? 'is-active'
                        : '')
                }
            >
                h4
            </button>
            <button
                onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 5 }).run()
                }
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('heading', { level: 5 })
                        ? 'is-active'
                        : '')
                }
            >
                h5
            </button>
            <button
                onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 6 }).run()
                }
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('heading', { level: 6 })
                        ? 'is-active'
                        : '')
                }
            >
                h6
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('bulletList') ? 'is-active' : '')
                }
            >
                bullet list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('orderedList') ? 'is-active' : '')
                }
            >
                ordered list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('codeBlock') ? 'is-active' : '')
                }
            >
                code block
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={
                    'btn btn-primary m-1 ' +
                    (editor.isActive('blockquote') ? 'is-active' : '')
                }
            >
                blockquote
            </button>
            <button
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
                className="btn btn-primary m-1"
            >
                horizontal rule
            </button>
            <button
                onClick={() => editor.chain().focus().setHardBreak().run()}
                className="btn btn-primary m-1"
            >
                hard break
            </button>
            <button
                onClick={() => editor.chain().focus().undo().run()}
                className="btn btn-primary m-1"
            >
                undo
            </button>
            <button
                onClick={() => editor.chain().focus().redo().run()}
                className="btn btn-primary m-1"
            >
                redo
            </button>
            <button
                onClick={() => {
                    triggerExport();
                }}
                className="btn btn-secondary m-1"
            >
                export to console
            </button>
        </>
    );
};

type RSEditorProps = {
    text: string;
};

const RSEditor = ({ text }: RSEditorProps) => {
    const editor = useEditor({
        extensions: [StarterKit],
        editorProps: {
            attributes: {
                class: 'h-100',
            },
        },
        content: JSON.parse(text),
    });
    if (editor == null) {
        return <div>{"Can't display Editor"}</div>;
    }

    const triggerExport = () => {
        console.log(JSON.stringify(editor.getJSON()));
    };

    return (
        <div className="h-100">
            <MenuBar editor={editor} triggerExport={triggerExport} />
            <EditorContent style={{ height: '200px' }} editor={editor} />
        </div>
    );
};

const FormUpload = (): JSX.Element => {
    const [file, setFile] = useState('');
    return (
        <RSFormInput
            id="clinic-images-upload"
            label={i18next.t('common.clinic.imageupload')}
            ariaLabel={i18next.t('common.clinic.imageupload')}
            type="file"
            value={file}
            onChange={setFile}
        />
    );
};
