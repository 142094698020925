import React, { useMemo } from 'react';
import { FilledStar, Star } from '../../svg';

export type RSRatingProps = {
    rating: number;
    max: number;
    verified?: boolean;
};

export const RSRating = ({
    rating,
    max,
    verified = false,
}: RSRatingProps): JSX.Element => {
    if (rating > max) return <span></span>;

    const stars = useMemo(() => {
        const stars = [];
        for (let i = 0; i < rating; i++) {
            stars.push(<FilledStar key={i} />);
        }
        for (let i = rating; i < max; i++) {
            stars.push(<Star key={i} />);
        }
        return stars;
    }, [rating, max]);

    const cssClasses = useMemo(() => {
        return 'fg-primary' + (verified ? ' text-success' : '');
    }, [verified]);
    return <span className={cssClasses}>{stars}</span>;
};
