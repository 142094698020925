import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type RSFormInputType = 'text' | 'password' | 'email' | 'file' | 'datetime-local';

export type RSFormInputProps = {
    id: string;
    label: string;
    ariaLabel: string;
    type?: RSFormInputType; 
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
};

export const RSFormInput = ({
    id,
    type = 'text',
    label,
    ariaLabel,
    value,
    onChange,
    disabled = false
}: RSFormInputProps): JSX.Element => {
    const { t } = useTranslation()
    const change = useCallback(
        (change: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(change.target.value);
        },
        [onChange],
    );
    return (
        <div>
            <label htmlFor={id} className="form-label">
                {t(label)}
            </label>
            <input
                id={id}
                type={type}
                className="form-control"
                aria-label={t(ariaLabel)}
                value={value}
                onChange={change}
                disabled={disabled}
            />
        </div>
    );
};
