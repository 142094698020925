import React from 'react';
import { RSImage } from '../image';

export type RSAwardsProps = {
    images: Array<string>;
};

export const RSAwards = ({ images }: RSAwardsProps): JSX.Element => {
    return (
        <div className="mt-3 d-container">
            <div className="row row-cols-6">
                {images.map((image) => (
                    <RSImage
                        key={image}
                        src={image}
                        height="100%"
                        width="100%"
                    />
                ))}
            </div>
        </div>
    );
};
