import React from 'react';
import {RSNavBarBrand} from '.';
import {MaybeArray} from '../../helper';
import './RSMenu.scss';
import {RSNavBarToggler} from './RSNavBarToggler';
import {RSNavContainer} from './RSNavContainer';


export type RSNavProps = {
    id: string,
    collapsed: boolean,
    toggleCollapse: () => void,
    brand?: {
        imageSrc?: string,
        text?: string
    },
    toggler: {
        ariaLabel: string,
    },
    children: MaybeArray<JSX.Element>
};
export const RSNav = ({id, collapsed, toggleCollapse, brand, toggler, children}: RSNavProps): JSX.Element => {
  return <nav className="navbar navbar-expand-lg navbar-dark bg-primary" role="navigation">
    <div className="container-fluid">
      <RSNavBarBrand imageSrc={brand?.imageSrc} text={brand?.text} />
      <RSNavBarToggler id={id} expanded={collapsed} ariaLabel={toggler.ariaLabel} click={toggleCollapse} />
      <RSNavContainer id={id} collapsed={collapsed}>
        {children}
      </RSNavContainer>
    </div>
  </nav>;
};
