import React from 'react';
export type RSCardImageProps = {
    src: string;
    alt: string;
    height?: number;
    width?: number;
};
export const RSCardImage = ({
    src,
    alt,
    height = 100,
    width = 100,
}: RSCardImageProps): JSX.Element => {
    return (
        <div>
            <img
                src={src}
                className="card-img-top"
                style={{ height: height + '%', width: width + '%' }}
                alt={alt}
            />
        </div>
    );
};
