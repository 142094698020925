import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    RSCard,
    RSCardBody,
    RSCollapse,
    RSHighlightsLogos,
} from '../../common';
import { RSAwards } from '../../common/components/advanced/RSAwards';
import { LoadableComponent } from '../../common/helper';
import { CaretDown, CaretUp } from '../../common/svg';

export type ClinixBodyProps = {
    text: string;
    highlights: Array<{ logo: string; name: string }>;
    languages: Array<string>;
    awards: Array<string>;
} & LoadableComponent;
export const ClinicBody = ({
    text,
    highlights,
    languages,
    awards,
}: ClinixBodyProps): JSX.Element => {
    const { t } = useTranslation();
    const [languageVisible, setLanguageVisible] = useState(false);
    const [awardVisible, setAwardVisible] = useState(false);
    const calculatedHighlights = useMemo(() => {
        const result = [];
        for (let i = 0; i < highlights.length; i = i + 3) {
            result.push(highlights.slice(i, i + 3));
        }
        return result;
    }, [highlights]);
    return (
        <div>
            <RSCard className="mt-5 mb-3">
                <RSCardBody>
                    {text !== '' ? <RSDisplayHtml text={text}/> : <div></div>}
                </RSCardBody>
            </RSCard>
            <RSCard>
                <RSCardBody>
                    <div className="container">
                        <h4>{t('common.clinic.highlights')}</h4>
                        {calculatedHighlights.map((e, index) => (
                            <div key={index} className="row">
                                {e.map((column) => (
                                    <div key={column.name} className="col">
                                        <RSHighlightsLogos type={column.logo} />
                                        <span className="text-dark">
                                            {column.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <a
                            onClick={() =>
                                setLanguageVisible((visible) => !visible)
                            }
                        >
                            <h4 className="mt-3">
                                {t('common.clinic.languages')}
                                <span>
                                    {languageVisible ? (
                                        <CaretUp />
                                    ) : (
                                        <CaretDown />
                                    )}
                                </span>
                            </h4>
                        </a>
                        <RSCollapse visible={languageVisible}>
                            <div>
                                {languages.map((l) => (
                                    <span key={l}>
                                        {t('language.' + l)}
                                        {', '}
                                    </span>
                                ))}
                            </div>
                        </RSCollapse>
                        <a
                            onClick={() =>
                                setAwardVisible((visible) => !visible)
                            }
                        >
                            <h4 className="mt-3">
                                {t('common.clinic.certification')}
                                <span>
                                    {awardVisible ? (
                                        <CaretUp />
                                    ) : (
                                        <CaretDown />
                                    )}
                                </span>
                            </h4>
                        </a>

                        <RSCollapse visible={awardVisible}>
                            <RSAwards images={awards} />
                        </RSCollapse>
                    </div>
                </RSCardBody>
            </RSCard>
        </div>
    );
};

type RSDisplayHtmlProps = {
    text: string;
};

const RSDisplayHtml = ({ text }: RSDisplayHtmlProps): JSX.Element => {
    const editor = useEditor({
        editable: false,
        extensions: [StarterKit],
        content: text === '' ? {} : JSON.parse(text),
    });
    return <EditorContent editor={editor}/>;
};
