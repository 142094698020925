import React, { useEffect } from 'react';
import { useReducer, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    RSPageContainer,
    RSList,
    RSPagination,
    http,
    DBRequest,
    RSButton,
} from '../../common';
type User = {
    uuid: string;
    userName: string;
    created: string;
};

type UserPage = {
    number: number;
    etag: string;
    users: Array<User>;
};
type UserListState = {
    pages: {
        [x: number]: UserPage;
    };
    currentPage: number;
    totalPages: number;
};

type AddPage = {
    type: 'AddPage';
    payload: {
        number: number;
        etag: string;
        users: Array<User>;
        totalPageNumber: number;
    };
};

type SetCurrentPage = {
    type: 'SetCurrentPage';
    payload: {
        currentPage: number;
    };
};

type Actions = AddPage | SetCurrentPage;

function UserListReducer(state: UserListState, action: Actions): UserListState {
    switch (action.type) {
        case 'AddPage':
            state.pages[action.payload.number] = {
                number: action.payload.number,
                users: action.payload.users,
                etag: action.payload.etag,
            };
            return { ...state, totalPages: action.payload.totalPageNumber };
        case 'SetCurrentPage':
            return { ...state, currentPage: action.payload.currentPage };
    }
}
export const UserList = (): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [userList, userListDispatcher] = useReducer(UserListReducer, {
        pages: [],
        currentPage: 0,
        totalPages: 0,
    });
    // TODO: check if dependencies correct
    const changePage = useCallback((page: number) => {
        userListDispatcher({
            type: 'SetCurrentPage',
            payload: { currentPage: page },
        });
        const etag = userList.pages[page].etag;
        http.get<DBRequest<User>>(`/user/page?pageNumber=${page}&pageSize=10`, {
            headers: { 'if-none-match': etag == undefined ? '' : etag },
        }).then((response) => {
            if (response.request.status == 304) return;
            userListDispatcher({
                type: 'AddPage',
                payload: {
                    users: response.data.data?.elements || [],
                    etag: response.headers['etag'] || '',
                    number: response.data.data?.pageNumber || 0,
                    totalPageNumber:
                        response.data.data?.totalNumberOfPages || 0,
                },
            });
        });
    }, []);
    const currentPage = useMemo(() => {
        const pages = userList.pages[userList.currentPage]?.users;
        if (pages == undefined) return [];
        return pages;
    }, [userList]);

    useEffect(() => {
        http.get<DBRequest<User>>(
            `/user/page?pageNumber=${userList.currentPage}&pageSize=10`,
        )
            .then((response) => {
                userListDispatcher({
                    type: 'AddPage',
                    payload: {
                        users: response.data.data?.elements || [],
                        etag: response.headers['ETag'],
                        number: response.data.data?.pageNumber || 0,
                        totalPageNumber:
                            response.data.data?.totalNumberOfPages || 0,
                    },
                });
            })
            .catch((error) => console.log(error));
    }, []);

    const onOpenUser = useCallback((uuid: string) => {
        navigate(`/admin/user/${uuid}`);
    }, []);

    return (
        <RSPageContainer className="h-75">
            <RSButton
                className="mb-2"
                type="button"
                onClick={() => onOpenUser('new')}
            >
                {t('common.create')}
            </RSButton>
            <UserListHead />
            <RSList
                elements={currentPage}
                render={(el) => (
                    <div>
                        <UserListElement data={el} onClick={onOpenUser} />
                    </div>
                )}
            />
            <div className="container">
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <RSPagination
                            ariaLabel={t('common.pagination.aria.label')}
                            nextText={t('common.pagination.next')}
                            previousText={t('common.pagination.previous')}
                            total={userList.totalPages}
                            onChange={changePage}
                            value={userList.currentPage}
                        />
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </RSPageContainer>
    );
};

const UserListHead = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="list-group-item mb-1 bg-secondary">
            <div className="d-container">
                <div className="row">
                    <div className="col">{t('common.user.uuid')}</div>
                    <div className="col">{t('common.user.username')}</div>
                    <div className="col">{t('common.user.created')}</div>
                </div>
            </div>
        </div>
    );
};

type UserListElementProps = {
    data: User;
    onClick: (uuid: string) => void;
};
const UserListElement = ({
    data,
    onClick,
}: UserListElementProps): JSX.Element => {
    const dateTime = useMemo(() => {
        const dateObject = new Date(data.created);
        return (
            dateObject.toLocaleDateString() +
            ' ' +
            dateObject.toLocaleTimeString()
        );
    }, [data.created]);
    const clickOnComponent = useCallback(() => onClick(data.uuid), [data.uuid]);
    return (
        <a
            className="d-container link-dark"
            style={{ textDecoration: 'none' }}
            onClick={clickOnComponent}
        >
            <div className="row">
                <div className="col">
                    <span>{data.uuid}</span>
                </div>
                <div className="col">
                    <span>{data.userName}</span>
                </div>
                <div className="col">
                    <span>{dateTime}</span>
                </div>
            </div>
        </a>
    );
};
