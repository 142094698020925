import React from 'react';
import {useMemo} from 'react';
import {MaybeArray} from '../../helper';

export type RSInputGroupContainerProps = {
    className?: string,
    children: MaybeArray<JSX.Element>
}

export const RSInputGroupContainer = ({children, className}: RSInputGroupContainerProps): JSX.Element => {
  const classes = useMemo(() =>{
    return 'input-group ' + (className === undefined ? '' : className);
  }, [className]);
  return <div className={classes}>{children}</div>;
};
