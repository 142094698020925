import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { API_URL, applicationStore } from "../App";

export type RestResponse<T> = {
    data?: T;
    status: number;
    msg?: string;
    etag?: string;
};

class Http {
    private instance: AxiosInstance | undefined = undefined

    private get http(): AxiosInstance {
        return this.instance != undefined ? this.instance : this.init();
    }

    init() {
        const http = axios.create({
            baseURL: API_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Credetials': false,
                'X-Requested-With': 'XMLHttpRequest',
            },
            withCredentials: true
        });

        http.interceptors.request.use(injectBearerToken, (error) => error);
        http.interceptors.response.use((response) => response, (error: AxiosError) => refreshTokenInterceptor(error, http))

        this.instance = http;
        return this.instance;
    }

    request<T = never, R = AxiosResponse<RestResponse<T>>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(url, config)
    }

    get<T = never, R = AxiosResponse<RestResponse<T>>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(url, config)
    }

    post<RQ, T = never, R = AxiosResponse<RestResponse<T>>>(url: string, data: RQ, config?: AxiosRequestConfig): Promise<R> {
        return this.http.post<T, R>(url, data, config)
    }

    put<RQ, T = never, R = AxiosResponse<RestResponse<T>>>(url: string, data: RQ, config?: AxiosRequestConfig): Promise<R> {
        return this.http.put<T, R>(url, data, config)
    }

    delete<T = never, R = AxiosResponse<RestResponse<T>>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.delete<T, R>(url, config)
    }
}

function injectBearerToken(value: AxiosRequestConfig): AxiosRequestConfig {
    //Don't inject when login or refresh request
    if (value.url == '/auth/login' || value.url == '/auth/refresh') return value;
    const accessToken = localStorage.getItem("access-token")
    if (accessToken == undefined) return value;

    if (value.headers == undefined) {
        value.headers = {}
    }
    value.headers['Authorization'] = `Bearer ${accessToken}`;
    return value;
}

export type RefreshTokenRequest = {
    refreshToken: string
};

export type RefreshTokenModel = {
    token: string;
    refreshToken: string;
    group: string;
};



let refreshing = false;
const requestQueue: Array<{ resolve: () => void; reject: (error: AxiosError | Promise<AxiosError<any, any>>) => void; }> = [];

function workOnQueue(error: AxiosError, failed: boolean) {
    requestQueue.forEach(promise => {
        if (failed) {
            promise.reject(error)
        } else {
            promise.resolve()
        }
    });
}
async function refreshTokenInterceptor(error: AxiosError, http: AxiosInstance): Promise<AxiosError> {
    if (refreshing) {
        return new Promise<void>((resolve, reject) => {
            requestQueue.push({ resolve, reject });
        }).then(() => {
            return http.request(error.config)
        })
    }

    const refreshToken = localStorage.getItem("rf-token")
    if (refreshToken !== null && refreshToken !== '' && error.response?.status === 401 && error.config.url !== '/auth/refresh') {
        const setGroup = applicationStore.getState().setGroup;
        try {
            refreshing = true;
            const response = await http.post<RefreshTokenRequest, AxiosResponse<RestResponse<RefreshTokenModel>>>("/auth/refresh", { refreshToken: refreshToken })
            if (response.data.data == undefined) throw Error("Could not retrieve body")
            localStorage.setItem("rf-token", response.data.data.refreshToken)
            localStorage.setItem("access-token", response.data.data.token)
            localStorage.setItem("group", response.data.data.group)
            setGroup(response.data.data.group)
            workOnQueue(error, true)
        } catch (refreshError: AxiosError | any) {
            localStorage.removeItem("rf-token")
            localStorage.removeItem("access-token")
            localStorage.removeItem("group")
            location.href = "/login"
            setGroup("")
            workOnQueue(refreshError, false)
            return Promise.reject(refreshError)
        } finally {
            refreshing = false;
        }

        return http.request(error.config)
    }
    return Promise.resolve(error)
}



export const http = new Http();