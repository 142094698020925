import React, {useRef, useState} from 'react';
import {RSInputGroupButton, RSInputGroupContainer, RSInputGroupText, RSInputGroupInput, RSInputGroupInputProps, RSInputGroupButtonProps} from '..';
import {RSSearch} from '../../svg';
import {RSAutoCompleteDropDownNgram} from '../auto-complete';

export type RSSearchBarProps = {
    input: RSInputGroupInputProps,
    button: RSInputGroupButtonProps
}

const suggestions = ['RBayern', 'RBayernNord', 'Südbayern', 'OstBayern', 'MünchenBayern', 'RosenheimBayern', 'Erding', 'Innsbruck', 'Innichen', 'Bozen', 'Rom', 'Toring'];

// TODO: testing
export const RSSearchBar = ({button, input}: RSSearchBarProps): JSX.Element => {
  const sugg = useRef(suggestions);
  return <div>
    <RSInputGroupContainer>
      <RSInputGroupText><RSSearch/></RSInputGroupText>
      <RSInputGroupInput {...input} />
      <RSInputGroupButton {...button} />
    </RSInputGroupContainer>
  </div>;
};
