import React, { useEffect, useState } from 'react';
import { http } from '../../Http';
import { RSRating } from './RSRating';

export type RSCommentProp = {
    rating: number;
    userUuid: string;
    text: string;
    date: string;
    verified: boolean;
};

const dateFormater = new Intl.DateTimeFormat(navigator.language, { year:"numeric", month:"short"})

export const RSComment = ({
    rating,
    userUuid,
    text,
    date,
    verified
}: RSCommentProp): JSX.Element => {
    const [name, setName] = useState('');

    useEffect(() => {
        http.get<string>(`/user/name/${userUuid}`).then((response) => {
            setName(response.data.data ?? '');
        });
    }, [userUuid, setName]);

    return (
        <div className="d-container">
            <div className="row">
                <div className="col">
                    <h5>{name}</h5>
                </div>
                <div className="col text-end">{dateFormater.format(new Date(date))}</div>
            </div>
            <div className="row">
                <span>
                    <RSRating rating={rating} max={5} verified={verified} />
                </span>
            </div>
            <div className="row">
                <p>{text}</p>
            </div>
        </div>
    );
};
