import create from 'zustand';
import { Clinic, ClinicComplete } from '../common';

type ClinicDasboard = ClinicComplete & {
    etag: string;
};

export type DashboardStoreType = {
    clinics: Record<string, ClinicDasboard>;
    searchedClinics: Record<string, ClinicDasboard>;
    setClinic: (clinic: ClinicDasboard) => void;
    setSearchedClinic: (clinic: ClinicDasboard) => void;
    clearSearched: () => void;
};

export const dashboardStore = create<DashboardStoreType>((set, get) => ({
    clinics: {},
    searchedClinics: {},
    setClinic: (clinic: ClinicDasboard) => {
        const clinics = get().clinics;
        clinics[clinic.uuid] = clinic;
        set({ ...get(), clinics: clinics });
    },
    setSearchedClinic: (clinic: ClinicDasboard) => {
        const clinics = get().clinics;
        clinics[clinic.uuid] = clinic;

        const searchedClinics = get().searchedClinics;
        searchedClinics[clinic.uuid] = clinic;

        set({ ...get(), clinics: clinics, searchedClinics: searchedClinics });
    },
    clearSearched: () => set({ ...get(), searchedClinics: {} }),
}));
