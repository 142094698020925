import React from 'react';
import {useCallback, useMemo, useState} from 'react';

export type RSPaginationProps = {
    value: number;
    onChange: (value: number) => void;
    total: number;
    previousText: string;
    nextText: string;
    ariaLabel: string;
}
export const RSPagination = ({
  value,
  onChange,
  total,
  previousText,
  nextText,
  ariaLabel,
}: RSPaginationProps): JSX.Element => {
  const [previousEnabled, setPreviousEnabled] = useState<boolean>(true);
  const [nextEnabled, setNextEnabled] = useState<boolean>(true);

  const previousClasses = useMemo(() => 'page-item' + previousEnabled ? 'disabled' : '', [previousEnabled]);
  const nextClasses = useMemo(() => 'page-item' + nextEnabled ? 'disabled' : '', [nextEnabled]);


  const onChangeCallback = useCallback((pageNumber: number) => {
    onChange(pageNumber);
  }, [onChange]);

  return <nav aria-label={ariaLabel}>
    <ul className="pagination">
      <li className={previousClasses}>
        <span className="page-link" onClick={() => onChangeCallback(value - 1)}>{previousText}</span>
      </li>
      {
        Array(total).fill(0).map((_, index) => {
          const classes = 'page-item';
          return <li className={classes} key={index}>
            <a className="page-link" onClick={() => onChangeCallback(index)}>{index + 1}</a>
          </li>;
        })
      }
      <li className={nextClasses}>
        <a className="page-link" href="#" onClick={() => onChangeCallback(value + 1)}>{nextText}</a>
      </li>
    </ul>
  </nav>;
};
