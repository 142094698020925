import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
    HashRouter,
    Routes,
    Route,
    Router,
    Outlet,
    useNavigate,
} from 'react-router-dom';

import './index.scss';

import {
    GrowingSpinner,
    http,
    NotFound,
    RefreshTokenModel,
    RefreshTokenRequest,
    RestResponse,
    routes,
} from './common';
import { Index as PublicIndex } from './public/Index';
import { Root } from './public/Root';
import { Clinic } from './public/clinic';

import { Index as AdminIndex, LoginPage } from './admin';

import { EN } from './translations';
import { ClinicEdit, ClinicList } from './admin/clinic';
import { UserEdit, UserList } from './admin/user';
import { Settings } from './admin/settings';
import create from 'zustand';
import { AxiosResponse } from 'axios';

//should always end with slash
export const API_URL = "https://clinic-api.ifloop.org/"

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: true,
        resources: {
            en: EN,
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });
export type ApplicationStoreType = {
    group: string;
    setGroup: (group: string) => void;
};
export const applicationStore = create<ApplicationStoreType>((set) => ({
    group: '',
    setGroup: (group: string) => set({ group: group }),
}));

const IndexRoute = (): JSX.Element => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [group, setGroup] = applicationStore((state) => [
        state.group,
        state.setGroup,
    ]);

    useEffect(() => {
        const refreshToken = localStorage.getItem('rf-token');
        if (refreshToken == null) {
            localStorage.removeItem('rf-token');
            localStorage.removeItem('access-token');
            localStorage.removeItem('group');
            setGroup('');
            setLoading(false);
            navigate('/login');
            return;
        }
        http.post<RefreshTokenRequest, RefreshTokenModel>('/auth/refresh', {
            refreshToken: refreshToken,
        })
            .then((response) => {
                if (response?.data?.data?.refreshToken == null) {
                    localStorage.removeItem('rf-token');
                    localStorage.removeItem('access-token');
                    localStorage.removeItem('group');
                    setGroup('');
                    setLoading(false);
                    navigate('/login');
                } else {
                    localStorage.setItem(
                        'rf-token',
                        response.data.data.refreshToken,
                    );
                    localStorage.setItem(
                        'access-token',
                        response.data.data.token,
                    );
                    localStorage.setItem('group', response.data.data.group);
                    setGroup(response.data.data.group);
                    setLoading(false);
                }
            })
            .catch((ex) => {
                localStorage.removeItem('rf-token');
                localStorage.removeItem('access-token');
                localStorage.removeItem('group');
                setGroup('');
                setLoading(false);
                navigate('/login');
            });
    }, [group]);
    return (
        <div className="w-100 h-100">
            {!loading ? (
                <Outlet />
            ) : (
                <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                    <div>
                        <GrowingSpinner height='80px' width='80px' />
                    </div>
                </div>
            )}
        </div>
    );
};

function App() {
    const [group] = applicationStore((state) => [state.group]);

    const isRoot = useMemo(() => {
        return group == 'root';
    }, [group]);
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<IndexRoute />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path={routes.root.path} element={<PublicIndex />}>
                        <Route path={routes.root.path} element={<Root />} />
                        <Route path={'clinic/:uuid'} element={<Clinic />} />
                    </Route>
                    {isRoot && (
                        <Route path="admin" element={<AdminIndex />}>
                            <Route path="clinic" element={<ClinicList />} />
                            <Route
                                path="clinic/:uuid"
                                element={<ClinicEdit />}
                            />
                            <Route path="user" element={<UserList />} />
                            <Route path="user/:uuid" element={<UserEdit />} />
                            <Route path="settings" element={<Settings />} />
                        </Route>
                    )}
                    <Route path="login" element={<LoginPage />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

export default App;
