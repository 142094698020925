import React from 'react';
import { MaybeArray } from '../../helper';

export type RSCollapseProps = {
    visible: boolean;
    className?: string;
    children: MaybeArray<JSX.Element>;
};
export const RSCollapse = ({
    visible,
    className,
    children,
}: RSCollapseProps): JSX.Element => {
    return (
        <div
            className={'collapse ' + (visible ? 'show ' : '') + (className ?? '')}
        >
            {children}
        </div>
    );
};
