import React from 'react';
import {useCallback, useRef, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {RSNav, RSNavBarBrandProps, RSNavItem, RSNavLink} from '../common';

const brand: RSNavBarBrandProps = {
  imageSrc: '/logo512.png',
  text: 'Reha_select',
};

const toggler = {
  ariaLabel: 'test',
};

export const Index = (): JSX.Element => {
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const brandRef = useRef(brand);
  const togglerRef = useRef(toggler);

  const toggleCollapse = useCallback(() => setCollapsed((prev) => !prev), [setCollapsed]);
  const changePage = useCallback((path: string): void => {
    navigate(path)
    setCollapsed(true);
  }, []);

  return <div className='w-100 h-100'>
    <RSNav id="public-menu" collapsed={collapsed} toggleCollapse={toggleCollapse} brand={brandRef.current} toggler={togglerRef.current}>
      <RSNavItem>
        <RSNavLink path="/admin/clinic" text="clinic" click={changePage} active={pathname} />
      </RSNavItem>
      <RSNavItem>
        <RSNavLink path="/admin/user" text="user" click={changePage} active={pathname} />
      </RSNavItem>
      <RSNavItem>
        <RSNavLink path="/settings" text="settings" click={changePage} active={pathname} />
      </RSNavItem>
    </RSNav>
    <Outlet />
  </div>;
};

export * from "./login";
