import React from 'react';
export type RSNavBarTogglerProps = {
    id: string,
    expanded: boolean,
    ariaLabel: string,
    click: () => void
}
export const RSNavBarToggler = ({id, expanded, ariaLabel, click}: RSNavBarTogglerProps): JSX.Element => {
  return <button
    className="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target={'#' + id}
    aria-controls={id}
    aria-expanded={!expanded}
    aria-label={ariaLabel}
    onClick={click}>
    <span className="navbar-toggler-icon" />
  </button>;
};
