import React from 'react';
import { StarFill } from '../../svg';

export type RSLogo = 'StarFill';

export type RSHighlightsLogosProps = {
    type: RSLogo | string; //TODO: remove string
};

export const RSHighlightsLogos = ({
    type,
}: RSHighlightsLogosProps): JSX.Element => {
    switch (type) {
        case 'StarFill':
            return <StarFill />;
        default:
            return <></>;
    }
};
