import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../App';
import {
    RSInputGroupButtonProps,
    RSInputGroupInputProps,
    RSSearchBar,
    RSPageContainer,
    http,
    ClinicComplete,
} from '../common';
import { RSClinicCard } from '../common/components/clinic-card';
import { dashboardStore } from './Dashboard-Store';

export const Root = (): JSX.Element => {
    const [value, setValue] = useState<string>('');
    const [
        clinics,
        searchedClinics,
        setClinics,
        clearSearched,
        setSearchedClinic,
    ] = dashboardStore((state) => [
        state.clinics,
        state.searchedClinics,
        state.setClinic,
        state.clearSearched,
        state.setSearchedClinic,
    ]);

    const search = useCallback((query: string) => {
        http.get<Array<string>>(`/clinic/search?query=${query}`).then(
            (response) => {
                response.data?.data?.forEach((clinicUuid) => {
                    http.get<ClinicComplete>(
                        `/clinic/dashboard/${clinicUuid}`,
                    ).then((clinicResponse) => {
                        if (clinicResponse.data?.data != null) {
                            setSearchedClinic({
                                ...clinicResponse.data.data,
                                etag: clinicResponse.headers['etag'],
                            });
                        }
                    });
                });
            },
        );
    }, []);

    const inputProps: RSInputGroupInputProps = {
        onChange: (value) => setValue(value),
        value: value,
        ariaDescribedBy: '',
        ariaLabel: '',
        placeholder: 'name',
    };

    const buttonProps: RSInputGroupButtonProps = {
        click: () => search(value),
        text: 'search',
    };

    useEffect(() => {
        http.get<Array<string>>(`/clinic/random`).then((response) => {
            response.data?.data?.forEach((uuid) => {
                http.get<ClinicComplete>(`/clinic/dashboard/${uuid}`).then(
                    async (clinicResponse) => {
                        if (clinicResponse.data?.data != null) {
                            setClinics({
                                ...clinicResponse.data.data,
                                etag: clinicResponse.headers['etag'],
                            });
                        }
                    },
                );
            });
        });
    }, []);
    useEffect(() => {
        clearSearched();
        if (!(value == null || value === '')) {
            search(value);
        }
    }, [value]);
    return (
        <RSPageContainer>
            <RSSearchBar input={inputProps} button={buttonProps} />
            <div className="container-fluid m-0 p-3">
                <div className="row row-cols-5">
                    {value == ''
                        ? Object.values(clinics ?? {}).map((clinic) => (
                              <ClinicCardLoader
                                  key={clinic.uuid}
                                  clinic={clinic}
                              />
                          ))
                        : Object.values(searchedClinics ?? {}).map((clinic) => (
                              <ClinicCardLoader
                                  key={clinic.uuid}
                                  clinic={clinic}
                              />
                          ))}
                </div>
            </div>
        </RSPageContainer>
    );
};

type ClinicCardLoader = {
    clinic: ClinicComplete;
};

const ClinicCardLoader = ({ clinic }: ClinicCardLoader): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onClick = useCallback((uuid: string) => {
        navigate(`/clinic/${uuid}`);
    }, []);
    return (
        <div className="p-1">
            <RSClinicCard
                onClick={() => onClick(clinic.uuid)}
                title={clinic.name}
                text=""
                footer={clinic.clinicDepartments.map((highlight) =>
                    t(`highlights.${highlight.name}`),
                )}
                image={{
                    alt: clinic.name,
                    src: `${API_URL}image/thumbnail/${clinic.uuid}`,
                }}
            />
        </div>
    );
};
