import { RSLogo } from '../common';

export const TestClinicData = {
    name: 'Klinik Höhenried',
    address: {
        street: 'Höhenried 1',
        zip: '82347',
        region: 'Bayern',
        city: 'Bernried',
        country: 'Germany',
    },
    googleMaps:
        'https://www.google.com/maps/place/H%C3%B6henrieder+Park/@47.8749127,11.2822416,17z/data=!4m13!1m7!3m6!1s0x479dc9ad2e5afe0f:0x43eb2c52ff75aca8!2sH%C3%B6henried,+82347+Bernried+am+Starnberger+See,+Deutschland!3b1!8m2!3d47.8745551!4d11.2836138!3m4!1s0x479dc919cc212993:0xad5012e40bebd22f!8m2!3d47.8773396!4d11.2832783',
    departments: [
        { name: 'Cardialogie', lang: 'de', etag: '' },
        { name: 'Orthopädie', lang: 'de', etag: '' },
        { name: 'Psychosomatik', lang: 'de', etag: '' },
    ],
    short_description: `Seit mehr als 50 Jahren ist die Klinik Höhenried für exzellente
    Rehabilitationsleistungen bekannt. Mit der Kardiologie, der
    Orthopädie und der Psychosomatik vereint die Klinik
    Höhenried die wichtigsten Gebiete der stationären und
    ambulanten Rehabilitation unter einem Dach.
    Sie möchten eine hochqualifizierte Rehabilitation direkt am
    Starnberger See absolvieren? Dann sind Sie bei uns richtig.`,
    description: `Eine Herzerkrankung ist für Patienten oft ein einschneidendes
    Erlebnis. Sie fragen sich: Werde ich wieder vollständig gesund?
    Kann ich meinen Alltag wieder selbstständig bewältigen? Werde
    ich meinen Beruf wieder ausüben können?
    Unser erfahrenes multidisziplinäres Team richtet die
    Behandlung nach Ihrer individuellen Herz-Kreislauf-Erkrankung
    aus.
    • Koronare Herzkrankheit aller Stadien, z.B. nach Herzinfarkt,
    mit Dilatation (PCI) und Stent oder nach Bypass-OP
    • Herzklappenerkrankungen, nach operativer oder
    interventioneller Versorgung
    • Entzündliche Herzerkrankungen, Kardiomyopathien, Rhyth-
    muserkrankungen, Gefäßerkrankungen, Lungenembolien
    • Herzinsuffizienz, Herzunterstützungssysteme und
    Herztransplantationen
    • Erwachsenen mit angeborenen Herzfehlern (EMAH)
    • Psychokardiologie
    Belegt werden die 214 Betten unserer Abteilung federführend
    durch die Deutsche Rentenversicherung (Bund und Länder)
    sowie durch die gesetzlichen und privaten Krankenkassen im
    Rahmen von Anschlussheilbehandlungen und Heilverfahren
    `,
    highlights: [
        { logo: 'StarFill' as RSLogo, name: 'WIFI', lang: 'de', etag: '' },
        { logo: 'StarFill' as RSLogo, name: 'Sauna', lang: 'de', etag: '' },
        { logo: 'StarFill' as RSLogo, name: 'Rennbahn', lang: 'de', etag: '' },
        { logo: 'StarFill' as RSLogo, name: 'GoKart', lang: 'de', etag: '' },
    ],
    languages: [
        {
            name: 'Englisch',
            lang: 'de',
            etag: '',
        },
        { name: 'Französisch', lang: 'de', etag: '' },
        { name: 'Italienisch', lang: 'de', etag: '' },
        { name: 'Spanisch', lang: 'de', etag: '' },
        { name: 'Kroatisch', lang: 'de', etag: '' },
        { name: 'Kurdisch', lang: 'de', etag: '' },
        { name: 'Polnisch', lang: 'de', etag: '' },
        { name: 'Arabisch', lang: 'de', etag: '' },
        { name: 'Serbisch', lang: 'de', etag: '' },
    ],
    avgRating: 4,
    comments: [
        {
            rating: 1,
            name: 'Max Mustermann',
            text: 'Der Pool hatte kein Wasser',
        },
        {
            rating: 5,
            name: 'Erika Ipsum',
            text: 'Das Personal war Top!',
        },
        {
            rating: 3,
            name: 'Max Pain',
            text: 'Es hatte sehr viel geregnet',
        },
    ],
};
