import React from 'react';
import { RSBadge } from '..';
import { MaybeArray } from '../../helper';
import {
    RSCard,
    RSCardBody,
    RSCardButton,
    RSCardButtonProps,
    RSCardFooter,
    RSCardImage,
    RSCardImageProps,
    RSCardText,
    RSCardTitle,
} from '../card';

export type RSClinicCardProps = {
    className?: string;
    image: RSCardImageProps;
    title: string;
    text: string;
    footer: Array<string>;
    onClick: () => void;
};

// TODO: test
export const RSClinicCard = ({
    className,
    image,
    title,
    text,
    footer,
    onClick
}: RSClinicCardProps): JSX.Element => {
    return (
      <button style={{all: 'unset'}} onClick={onClick}>
        <RSCard className={className ?? ''}>
            <RSCardBody>
                <RSCardImage {...image} />
                <RSCardTitle>{title}</RSCardTitle>
                <RSCardText text={text} />
            </RSCardBody>
            <RSCardFooter>
                {footer.map((element, index) => (
                    <RSBadge key={index} text={element} />
                ))}
            </RSCardFooter>
        </RSCard>
      </button>
    );
};
