import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircle } from '../../svg';
export type RSListProps<ELEMENT> = {
    elements: Array<ELEMENT>;
    render: (value: ELEMENT) => JSX.Element;
};

export function RSList<ELEMENT>({
    elements,
    render,
}: RSListProps<ELEMENT>): JSX.Element {
    const { t } = useTranslation();
    return (
        <div className="h-100">
            {elements.length > 0 ? (
                <ul className="list-group">
                    {elements.map((element, index) => (
                        <li
                            className="list-group-item list-group-item-action"
                            key={index}
                        >
                            {render(element)}
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="container mt-5">
                    <div className="row">
                        <div className="col"></div>
                        <div className="col d-flex justify-content-center align-items-center">
                            <InfoCircle className='mt-0 mb-1 mr-1'/>
                            <h5>{t('common.list.empty')}</h5>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            )}
        </div>
    );
}
