import React from 'react';
export type RSImageProps = {
    src: string;
    height: string;
    width: string;
    className?: string;
    alt?: string;
};
export const RSImage = ({
    src,
    className,
    alt,
    height,
    width,
}: RSImageProps): JSX.Element => {
    return (
        <img
            src={src}
            height={height}
            width={width}
            className={className ?? ''}
            alt={alt}
        />
    );
};
