import { RSTranslations } from './types';

export const EN: RSTranslations = {
    translation: {
        common: {
            firstname: 'First Name',
            lastname: 'Last Name',
            created: 'Created',
            email: 'Email',
            tel: 'Telephone',
            add: 'Add',
            username: {
                name: 'User Name',
                missing: 'Missing User Name',
            },
            password: { name: 'Password', missing: 'Missing Password' },
            login: {
                name: 'Login',
                not: {
                    successful: 'Login was not successful',
                },
            },
            list: {
                empty: 'This list is empty',
            },
            contact: {
                information: {
                    name: 'Contact Information',
                    type: 'Type',
                    value: 'Value',
                },
            },
            clinic: {
                'all-comments': 'All Comments',
                'avg-rating': 'Median Rating',
                certification: 'Certifications',
                highlights: 'Highlights',
                languages: 'Languages',
                'selected-comments': 'Selected Comments',
            },
            loading: 'Loading...'
        },
        language: {
            de: 'Deutsch',
            fr: 'French',
            it: 'Italian',
            pl: 'Polish',
        },
        highlights: {
            Cardiologie: 'Cardialogie',
            Orthopädie: 'Orthopädie',
            Psychosomatik: 'Psychosomatik',
        },
        bootstrap: {
            toggle_navigation: 'Toggle navigation',
        },
    },
};
